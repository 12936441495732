// src/hooks/useAuthStatus.js
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthenticationStatus } from '../store/userSlice';

export function useAuthStatus() {
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userRole = useSelector((state) => state.user.userRole);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true);
      await dispatch(checkAuthenticationStatus());
      setIsLoading(false);
    };
    checkAuth();
  }, [dispatch]);

  return { isLoading, isAuthenticated, userRole };
}
