import React from 'react';
import './WriterApplicationWords.css';

function CreateAccountWords() {
    return (
        <div className="create-account-words">
            <h3>What are you getting yourself into? </h3>
            <p>You’ve set up your account and can now apply to become a writer on the platform. This application is your gateway to publishing and making a profile on ShorterStories. </p>
            <p>The application process serves two essential purposes. First, it acts as a gatekeeper, filtering out bots and spammers—think of it as keeping the barbarians at the gate. This ensures that all content on the platform is created by legitimate writers.</p>
            <p>Second, it upholds a standard akin to a dress code. It's about maintaining a level of quality that enhances the reading and writing experience for everyone. This process aims to balance legitimacy and authenticity, while enabling anyone with a story to tell their tale.</p>
            <h3>But what do you get out of it? </h3>
            <p>At ShorterStories, we believe in prioritizing writers—the architects of storytelling—above all else. Our guiding principle is simple: Author first. Reader second. The money men who hand out the checks third.</p>
            <p>This isn't because we don't like money, and certainly not readers. It is because this business model is best for everyone in the industry.</p>
            <p>Our commitment to ensuring that writers' needs and success come first will provide the right incentives to grow the platform for everyone's benefit. Because when writers prosper, the ecosystem profits.</p>
            <p>A platform that values and supports writers attracts readers eager to discover fresh voices and compelling stories. As readers engage with content, they buy more books, and create relationships with writers, capturing the attention of industry stakeholders, including publishers, agents, all those who contribute to the financial ecosystem of publishing.</p>
            <p>This approach creates a virtuous cycle where the success of writers drives engagement, attracting investment and opportunities for growth. By prioritizing writers, we foster a dynamic and vibrant literary community that enriches the lives of readers, strengthens the publishing industry, and ensures a sustainable future for storytelling.</p>
            <h3>But what do you actually get out of it? </h3>
            <p>By joining ShorterStories as a writer, you will be able to create a profile, publish written pieces and market books. In so doing, ShorterStories opens up opportunities designed to enhance your visibility and monetize your work effectively. Here's what you can expect:</p>
            <p><strong>Monetization through Affiliate Links:</strong> Earn from the affiliate links (tagged books) included in your pieces and on your profile page. We split affiliate revenue 75/25 in your favor, and reinvest the majority of what we take to build out the platform.</p>
            <p><strong>Insightful Statistics:</strong> Gain a deep understanding of your audience with detailed analytics, providing insights into how readers engage with your work.</p>
            <p><strong>Targeted Exposure:</strong> Our algorithms and search methods connect your stories with the readers most likely to enjoy and engage with them, expanding your reach and fostering a loyal following.</p>
            <p><strong>Platform Growth:</strong> We're dedicated to evolving our product for the industry, ensuring you have access to the best tools and opportunities for success. As time goes by, we'll continue to innovate and support your journey as a writer.</p>
            <p>Welcome to ShorterStories, where your talent and creativity are celebrated, and your success is our business model.</p>
            <h3>By joining the platform what are your responsibilities? Is there anything you can't do on the platform?</h3>
            <p> In partnering with the affiliate networks, we promised to uphold the terms and conditions of both the affiliate networks we partner with and the specific terms of the booksellers we work with. By joining ShorterStories, you agree by proxy to these terms as well. So, what are your responsibilities?</p>
            <p><strong>Respect our affiliate partners and use the platform as intended: </strong> Earn from the affiliate links (tagged books) included in your pieces and on your profile page. Don’t stuff cookies. If we find you doing that, we'll have to remove you and cancel your commissions.</p>
            <p><strong>Avoid inappropriate content:</strong> We promised our affiliates we wouldn't promote pornography or hate speech. If we find you doing that, we'll have to remove you and cancel your commissions.</p>
            <p><strong>Maintain integrity:</strong> If you do anything damaging to our reputation or the platform in general, we'll likely remove you for that as well and cancel your commissions.</p>
            <p><strong>Respect intellectual property:</strong> Make sure you don't publish copyrighted material or steal from others.</p>
            <p>The idea is to keep to the spirit of the platform. In other words, be cool and respectful.</p>
            <p>And that's the skinny on that.</p>
        </div>
    );
}

export default CreateAccountWords;
