import React from 'react';
import './PieceWords.css';
import BooksInPiece from '../../components/BooksInPiece/BooksInPiece';

function numberToWord(num) {
  const words = ['one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten'];
  return words[num - 1] || num.toString();
}

function PieceWords({
  title,
  authorName,
  content,
  isSerial,
  serialPartNumber,
  previousSerialHash,
  nextSerialHash,
  onNextClick,
  onPreviousClick,
  booksInPiece,
}) {
  // Helper: Count words in HTML content
  function countWords(htmlContent) {
    const plainText = htmlContent.replace(/<\/?[^>]+(>|$)/g, ''); // Strip HTML tags
    return plainText.trim().split(/\s+/).length; // Count words
  }

  // Helper: Split content at a breakpoint
  function splitContentAtBreakpoint(htmlContent, breakpoint) {
    if (breakpoint === null) {
      return [htmlContent, null]; // No split if no breakpoint
    }

    return [
      htmlContent.slice(0, breakpoint), // First half of the HTML
      htmlContent.slice(breakpoint), // Second half of the HTML
    ];
  }

  // Helper: Find ad breakpoint in HTML content
  function findAdBreakpoint(htmlContent, searchRadius) {
    const midpoint = Math.floor(htmlContent.length / 2); // Approximate midpoint
    const lowerBound = Math.max(0, midpoint - searchRadius);
    const upperBound = Math.min(htmlContent.length, midpoint + searchRadius);

    // Search forward within the range
    for (let i = midpoint; i <= upperBound; i++) {
      if (htmlContent.slice(i, i + 4) === '</p>') {
        return i + 4; // Include the closing </p>
      }
    }

    // Search backward within the range
    for (let i = midpoint; i >= lowerBound; i--) {
      if (htmlContent.slice(i, i + 4) === '</p>') {
        return i + 4; // Include the closing </p>
      }
    }

    // No valid breakpoint found
    return null;
  }

  const wordCount = countWords(content);
  const searchRadius = 500; // Adjustable range
  const adBreakpoint = wordCount > 400 ? findAdBreakpoint(content, searchRadius) : null; // Only find breakpoint if > 400 words
  const [contentBeforeAd, contentAfterAd] = splitContentAtBreakpoint(content, adBreakpoint);

  return (
    <div className="pieces-words">
      <h3 className="pieces-title">
        {title}
        {isSerial && serialPartNumber && (
          <span className="profileFeed-serial-part">
            {' (part ' + numberToWord(serialPartNumber) + ')'}
          </span>
        )}
        <span className="authors-name">by {authorName}</span>
      </h3>

      {isSerial && (
        <div className="serial-navigation">
          <div
            className="serial-nav-link previous"
            onClick={previousSerialHash ? onPreviousClick : undefined}
          >
            {previousSerialHash && (
              <span>
                <span className="arrow">&#8592;</span>
                <span className="main-text">Previous piece</span>
                <br />
                <span className="sub-text">in series</span>
              </span>
            )}
          </div>
          <div
            className="serial-nav-link next"
            onClick={nextSerialHash ? onNextClick : undefined}
          >
            {nextSerialHash && (
              <span>
                <span className="main-text">Next piece</span>
                <span className="arrow">&#8594;</span>
                <br />
                <span className="sub-text">in series</span>
              </span>
            )}
          </div>
        </div>
      )}

      <div className="pieces-content">
        {/* First half of the content */}
        <div dangerouslySetInnerHTML={{ __html: contentBeforeAd }} />

        {/* BooksInPiece in the middle, only if there are books and the piece is > 400 words */}
        {contentAfterAd && booksInPiece?.feedItems?.length > 0 && (
          <div className="books-in-piece-ad">
            <div className="divider-piece"></div>
            <div className="promoted-books-text">Promoted books</div>
            <BooksInPiece {...booksInPiece} />
            <div className="divider-piece"></div>
          </div>
        )}

        {/* Second half of the content */}
        {contentAfterAd && <div dangerouslySetInnerHTML={{ __html: contentAfterAd }} />}
      </div>
    </div>
  );
}

export default PieceWords;
