import React, { useState, useEffect }  from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HeaderEditPreview.css';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { fetchAuthSession } from 'aws-amplify/auth';
import { apiInstance } from '../../utils/axiosInstance'; 
import {resetPublishingProcess} from '../../store/publishingSlice';
import {clearSelections} from '../../store/taggingSlice';

import defaultImage from '../../assets/images/birdie.jpeg';



function HeaderEditPreview() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  
  const [hasChanges, setHasChanges] = useState(false);
  const userId = useSelector(state => state.user.userId);
  const userName = useSelector(state => state.user.userName);
  const currentPhoto = useSelector(state => state.editing.currentPhoto) || defaultImage;
  const { data: authorProfile } = useSelector((state) => state.authorProfile);
  const [isPublishing, setIsPublishing] = useState(false);

  const {
    title,
    piece,
    taggedBooks,
    description,
    pieceHash,
    
    genre,
    style,
    isSerial,
    serialFirstProfileHash,
    serialPartNumber,
    previousSerialHash

  } = useSelector(state => state.editing);
  const changes = useSelector((state) => state.editing.changes);
  console.log("Tracked changes from Redux:", changes);

  console.log('title', taggedBooks);
  console.log('authorProfile', authorProfile);
  console.log('userName', userName);
  console.log('userId', userId);

  function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (cross-browser support)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
  }

  function countWords(text) {
    // Use regular expression to replace multiple spaces with a single space and trim spaces
    return text.replace(/\s+/g, ' ').trim().split(' ').length;
  }

  const wordCount = countWords(stripHtml(piece));


  const { mutate: publishPiece, isLoading, isError } = useMutation(async (formData) => {
    const session = await fetchAuthSession();
    const jwtToken = session.tokens.idToken.toString();

    console.log('formData', formData);

    const response = await apiInstance.post('/pieces/edit', formData, {
        headers: {
            'Authorization': `Bearer ${jwtToken}`,
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
}, {
    onSuccess: (data) => {
        console.log('Piece published successfully:', data);
        queryClient.invalidateQueries(['pieces']);
        queryClient.refetchQueries(['pieces']);
        queryClient.invalidateQueries(['profileFeed', userId, userName]);
        queryClient.refetchQueries(['profileFeed', userId, userName]); 
        dispatch(clearSelections()); // Assuming you have imported the slice as taggingSlice
        dispatch(resetPublishingProcess());
        navigate('/writers');
        setIsPublishing(false);
    },
    onError: (error) => {
        console.error('Error publishing piece:', error);
        alert('Failed to publish the piece. Please try again.');
        alert(error);
        setIsPublishing(false);
        
    },
});

  const navigateToProfile = () => {
    navigate('/writers/edit/details'); // Assuming '/profile' is the route for the profile page
  };

  const saveChanges = async () => {
    try {
      setIsPublishing(true);
  
      // Build the payload based on changes
      const payload = {
        piece_hash: pieceHash, // Always include the piece hash
      };
  
      // Add changed fields to the payload
      Object.entries(changes).forEach(([key, value]) => {
        // Map keys to backend-compatible fields
        const keyMapping = {
          piece: "piece_content",
          genre: "piece_genre",
          style: "piece_style",
        };
  
        const mappedKey = keyMapping[key] || key; // Use mapped key or original if not in mapping
        payload[mappedKey] = value.newValue; // Use only the new value
      });
  
  
      // If piece content changes, include word count
      if (changes.piece) {
        const updatedWordCount = countWords(stripHtml(changes.piece.newValue));
        payload.piece_length = updatedWordCount;
      }
  
      // Always include taggedBooks if they exist

      if (changes.taggedBooks) {
        payload.taggedBooksChanged = true; 
        payload.taggedBooks = changes.taggedBooks.newValue;
      }
  
      // Include currentPhoto as image_key if it's different from the default
      if (changes.currentPhoto) {
        const newPhoto = changes.currentPhoto.newValue;
        
        payload.image_key = newPhoto !== null ? newPhoto : "null"; // Pass S3 key or null explicitly
      }
  
      console.log("Payload to be sent:", payload);
  
      // Call the mutation
      await publishPiece(payload);
    } catch (error) {
      console.error("Error in saveChanges:", error);
      alert(`Error saving piece: ${error.message || error}`);
      setIsPublishing(false);
    }
  };
  

  return (
    <div className="header-preview-piece-container"> {/* New container div */}
      <div className="header-preview-piece">
        <span className="return-to-edit" onClick={navigateToProfile}>Return to edit</span>
        <span 
          className={`publish-piece ${isPublishing ? 'disabled' : ''}`} 
          onClick={!isPublishing ? saveChanges : undefined}
        >
          {isPublishing ? 'Publishing...' : 'Republish piece'}
        </span>
      </div>
    </div>
  );

}

export default HeaderEditPreview;