import React from 'react';
import './AboutWritersWords.css';
import { useNavigate } from 'react-router-dom';
import turbo from '../../assets/images/me-profile.png';
import birdie from '../../assets/images/piece-summary.png';
import bookswide from '../../assets/images/books-wide.png';
import writerLong from '../../assets/images/writer-longer.png';

function AboutWritersWords({ isAuthenticated, userRole }) {

    const navigate = useNavigate();

    const handleNavigation = (path) => {
        window.scrollTo(0, 0);
        navigate(path);
      };

    console.log('userROle',userRole);
    const renderCTA = () => {
        if (!isAuthenticated) {
          return (
            <div className="cta-section">
              <p>The first step to become a writer on ShorterStories is to create an account. </p>
              <button className="cta-button" onClick={() => handleNavigation('/login')}>
                Create a ShorterStories account/Log in
              </button>
            </div>
          );
        } else if (userRole === 'reader') {
          return (
            <div className="cta-section">
              <p>Want to take the next step and publish your own work?</p>
              <button className="cta-button" onClick={() => handleNavigation('/writers')}>
                Apply to Become a Writer
              </button>
            </div>
          );
        } else if (userRole === 'applicant') {
          return (
            <div className="cta-section">
              <p>Your writer application is under review.</p>
              <button className="cta-button" onClick={() => handleNavigation('/writers')}>
                Check Application Status
              </button>
            </div>
          );
        } else if (userRole === 'writer') {
          return (
            <div className="cta-section">
              <button className="cta-button" onClick={() => handleNavigation('/writers')}> 
                Go to the writer's homepage
              </button>
            </div>
          );
        } else {
            return (
                <div className="cta-section">
                  <p>Want to take the next step and publish on the platform?</p>
                  <button className="cta-button" onClick={() => handleNavigation('/writers')}>
                    Apply to become a writer
                  </button>
                </div>
              );
        }
      };

    return (
        <div className="about-writers-words">
            <h3>Writing is a tough gig</h3>
            <p>Building a readership can feel like punching at thin air, while earning decent money from writing is the butt of an old joke. And please god, let's never talk of the rejections.  </p>
            {/* <p>Meanwhile, the industry favors the big guys who already have a name for themselves.</p> */}
            <p>And all the while, all you were trying to do was tell a good story...</p>

            <img src={writerLong} alt="book-wide" className="uninlinable-image" />
            {/* <p>As a writer on ShorterStories, you are able to create a profile, publish written pieces and market books. In so doing, ShorterStories opens up opportunities designed to enhance your visibility and monetize your work effectively. Here's what's what:</p> */}
            <h3>ShorterStories to the rescue</h3>
            
            <p>ShorterStories is a place for writers to establish their voice, by publishing short form pieces and recommending books (either theirs or somebody else’s).</p>
            <p>The books you read and were inspired by and the writing you do builds a persona, a brand, for an audience to attach itself to. And, you earn commissions continuously on the books you recommend across the platform. </p>   
            {renderCTA()} 
            <hr className="subtle-line-aboutwords" />
            <h3>What about the reader? </h3>
            <p>For the reader the platform is an organic bookstore, a personal library, a literary home, where readers get to know writers and engage with them on their level. </p>
            <p>Learn more about what ShorterStories looks like to the reader.</p>
            <button className="cta-button" onClick={() => handleNavigation('/about/readers')}>
                ShorterStories for the reader
            </button>
            {/* To the writer it is a place to publish shorter pieces and earn money via the links to books they advertise. */}
            
            
            <hr className="subtle-line-aboutwords" />
            <h3>What you get on ShorterStories </h3>
            <p>As a writer on ShorterStories, you are able to create a profile, publish written pieces and market books. In so doing, ShorterStories opens up opportunities designed to enhance your visibility and monetize your work effectively. Here's what's what:</p>
            
            <div className="profile-section">
                <p><strong>1. Build a profile:</strong></p>
                <img src={turbo} alt="Profile-turbo" className="inlinable-image" />
            </div>
            <ul>
                <li><strong>Profile card:</strong> A cool, clean intro that gives readers a reason to read on.</li>
                <li><strong>Portfolio:</strong> Build a portfolio of work that showcases you.</li>
            </ul>
            <div className="profile-section">
                <p><strong>2. Write and publish:</strong></p>
                <img src={birdie} alt="birdie" className="inlinable-image" />
            </div>
            <ul>
                <li><strong>Publish what you want:</strong> Craft anything from a quick 50-word poem to a 5,000-word short story, or serialize your entire novel.</li>
                <li><strong>Clean, consistent editor:</strong> Focus on your piece with our clean formatting and easy to use editor.</li>
                <li><strong>Publish your way:</strong> Release standalone works or keep readers hooked with serialized content.</li>
            </ul>
            <p><strong>3. Monetize through affiliate links:</strong> Earn from the affiliate links (tagged books) included in your pieces and on your profile page. We split affiliate revenue 75/25 in your favor, and reinvest the majority of what we take to build out the platform.</p>
            <img src={bookswide} alt="book-wide" className="uninlinable-image" />
            <p><strong>4. Grow readership:</strong> Readers can discover your work through genres, styles, region and literary inspirations and other rabbit trails that lead between authors.</p>
            <p><strong>5. Insights:</strong> Know if you're being read and gain an understanding of your audience.</p>
        </div>
    );
}

export default AboutWritersWords;