import React from 'react';
import './CreateAccountWordsHome.css';

function CreateAccountWords() {
    return (
        <div className="create-account-words">
            <h3>What are you getting yourself into?</h3>
            <p>So, you're thinking about joining ShorterStories. Nice.</p>
            <p>When you're logged in, we keep track of the pieces you read and the books you click on. This is used to enhance your experience. It enables you to keep a personal library to save and organize books you're interested in and pieces you've read.</p>
            <p>This data also serves as the basis for a tailored reading experience that helps you find stories, books and authors you'll love.</p>
            <p>If you prefer not to have your reading activity recorded, you can always log out and use the platform anonymously. When you're not logged in, we don't track your interactions.</p>
            <p>Oh, and heads up: we might bug you from time to time with an email, but you can tell us to sod off if they're getting on your nerves.</p>

        </div>
    );
}

export default CreateAccountWords;
