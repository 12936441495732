import React from 'react';
import { Link } from 'react-router-dom';
import './FooterAboutWriter.css';

function Footer() {

  return (
    <div className="footer-create">
      <Link to="/" className="writer-footer-link">Return to home page</Link>
    </div>
  );
}

export default Footer;