import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Header.css';

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBasedOnPage = () => {

    if (location.pathname.startsWith('/piece/'))  {
      // navigate('/thefeed'); // Navigate to '/home' if on any '/piece/*' path
      navigate('/readers'); 
      return; // Prevent further checks after handling this case
    }

    if (location.pathname.startsWith('/writer/'))  {
      // navigate('/thefeed'); // Navigate to '/home' if on any '/piece/*' path
      navigate('/readers'); 
      return; // Prevent further checks after handling this case
    }
    if (location.pathname.startsWith('/readers/find'))  {
      // navigate('/thefeed'); // Navigate to '/home' if on any '/piece/*' path
      navigate('/readers'); 
      return; // Prevent further checks after handling this case
    }

    if (location.pathname.startsWith('/readers/library'))  {
      // navigate('/thefeed'); // Navigate to '/home' if on any '/piece/*' path
      navigate('/readers'); 
      return; // Prevent further checks after handling this case
    }
    // Define your navigation rules based on the current pathname
    switch(location.pathname) {




      case '/writers/profile':

        // navigate('/writers'); // If on '/pieces', go to '/home'
        navigate('/'); 
      case '/writers/delve-in-readers':
        navigate('/writers');

        break;
      // Add more cases as needed for different paths
      default:


        navigate('/'); // Default navigation, e.g., go home
    }
  };

  if (location.pathname !== '/') {
    return (
      <h1 className="header-main" onClick={navigateBasedOnPage} style={{ cursor: 'pointer' }}>
        ShorterStories.
      </h1>
    );
  }

  return <h1 className="header-main">ShorterStories.</h1>;
}

export default Header;