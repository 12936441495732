import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGenericModal } from '../../store/modalSlice';
import { setFilter, setShouldApplyFilters } from '../../store/theFeedSlice';
import './ModalFilterStyleTheFeed.css'; // Make sure to create this CSS file

const ModalFilterStyleTheFeed = () => {
  const dispatch = useDispatch();
  const currentStyles = useSelector(state => state.theFeed.style);
  console.log(currentStyles);
  const [selectedStyles, setSelectedStyles] = useState(currentStyles);

  const styles = [
    "Poetry", 
    "Prose", 
    "Review", 
    "Scene", 
    "Dialogue", 
    "Excerpt", 
    "Blurb", 
    "Chapter", 
    "Character study",
    "Verse", 
    "Joke",
  ];

  const handleStyleSelect = (style) => {
    setSelectedStyles(prevStyles => {
      if (prevStyles.includes(style)) {
        return prevStyles.filter(s => s !== style);
      } else {
        return [...prevStyles, style];
      }
    });
  };

  const handleSave = () => {
    dispatch(setFilter({ filterType: 'style', value: selectedStyles }));
    dispatch(setShouldApplyFilters(true));
    dispatch(hideGenericModal());
  };

  const handleClearAll = () => {
    setSelectedStyles([]);
  };

  const renderStyleItem = (style) => {
    const isSelected = selectedStyles.includes(style);
    return (
      <div 
        key={style} 
        className={`genreItem ${isSelected ? 'selected' : ''}`} 
        onClick={() => handleStyleSelect(style)}
      >
        <div className="label-container">
          {isSelected ? (
            <span className="genreText">&#8209;&#8209;&#8209;&gt;&#160;&#160;{style}&#160;&#160;&lt;&#8209;&#8209;&#8209;</span>
          ) : (
            <span className="genreText">{style}</span>
          )}
        </div>
      </div>
    );
  };


  return (
    <div className="modal-filter-style">
      <div className="modalBackground">
        <div className="modalContainer">
          <h2 className="modalTitle">Filter by Style</h2>
          <div className="genreList">
            {styles.map(renderStyleItem)}
          </div>
          <div 
            className={`genreItem ${selectedStyles.length === 0 ? 'selected' : ''}`} 
            onClick={handleClearAll}
          >
            <span className="genreText">Clear All</span>
          </div>
          <button className="infosButton" onClick={handleSave}>Apply Filters</button>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterStyleTheFeed;