import React from 'react';
import { Link } from 'react-router-dom';
import './FooterApplicationInfo.css';

function Footer() {

  return (
    <div className="footer-create">
      <Link to="/" className="what-up-footer-link">Return to home screen</Link>
    </div>
  );
}

export default Footer;