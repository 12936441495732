import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeaderHome from '../../components/HeaderHome/HeaderHome';
import AboutWords from '../../components/HomeDesign/HomeDesign';
// import WriterDashboard from '../../components/AboutWords/AboutWords';
import WriterDashboard from '../../components/HomeForWriters/HomeForWriters';
import ApplicantHome from '../../components/HomeForApplicants/HomeForApplicants';
import HomeForReaders from '../../components/HomeForReaders/HomeForReaders';
// import ReaderExperience from '../../components/ReaderExperience/ReaderExperience';
import Footer from '../../components/Footer/Footer';
import { checkAuthenticationStatus } from '../../store/userSlice';
import './NewHome.css';

function About() {
  const [isLoading, setIsLoading] = useState(true);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userRole = useSelector((state) => state.user.userRole);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      setIsLoading(true); // Show loading state while checking auth status
      await dispatch(checkAuthenticationStatus());
      setIsLoading(false);
    };
    checkAuth();
  }, [dispatch]);

  if (isLoading) {
    return <div className="loading">Loading...</div>;
  }

  let content;
  let pageTitle = "About | ShorterStories";
  let pageDescription = "Learn about ShorterStories, where writers and readers connect over stories and books.";

  if (!isAuthenticated) {
    // Unauthenticated users
    content = <AboutWords />;
    pageTitle = "ShorterStories | For writers, readers, and industry professionals";
    pageDescription = "Discover how ShorterStories serves writers, readers and the book industry.";
  } else if (userRole === 'writer') {
    // Authenticated writers
    content = <WriterDashboard />;
    pageTitle = "Welcome, Writer | ShorterStories";
    pageDescription = "Manage your profile, publish your stories, and grow your audience on ShorterStories.";
  } else if (userRole === 'applicant') {
    // Authenticated writers
    content = <ApplicantHome />;
    pageTitle = "Welcome, Writer | ShorterStories";
    pageDescription = "Manage your profile, publish your stories, and grow your audience on ShorterStories.";
  } else {
    // Authenticated readers
    content = <HomeForReaders />;
    pageTitle = "Discover Stories | ShorterStories";
    pageDescription = "Find new stories and connect with your favorite writers on ShorterStories.";
  }

  return (
    <div className="homepage">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <HeaderHome /> 
      <div className="homepage-content">
        {content}
      </div>
      <Footer />
    </div>
  );
}

export default About;
